<template>
  <div class="admin-wizard">
    <AdminHeader />
    <AdminBreadcrumb
     @on-saveContinue="onFormSubmit(true)"
     />
    <main class="content">
      <b-container>
        <b-row>
          <b-col md="3">
            <AdminSideNav />
          </b-col>
          <b-col md="9">
            <ValidationObserver ref="observer" v-slot="{ invalid }">
              <b-card>
                <b-card-body class="p-0">
                  <b-form @submit.prevent="onFormSubmit">
                    <b-card-title>
                      What would you like your intro text to say?                    
                    </b-card-title>
                    <div class="guide-tip">
                      <b-row align-v="center">
                        <b-col cols="3" lg="1" class="icon">
                          <font-awesome-icon :icon="['far', 'id-card']"/>
                        </b-col>
                        <b-col cols="12" lg="8" order="3" order-lg="2" class="text">                      
                          This can be used to share your bio, your work with the Guild, or any other introductory text you like.
                        </b-col>
                        <b-col cols="9" lg="3" order="2" order-lg="3" class="button">
                          <b-button variant="outline-secondary" size="sm" v-b-modal.modal-theme-guide>
                            View Example <font-awesome-icon :icon="['far', 'window-maximize']"/>
                          </b-button>
                        </b-col>
                      </b-row>
                    </div>                      
                    <b-alert :show="!!errorMessage" variant="danger">{{
                      errorMessage
                    }}</b-alert>
                    <b-form-group>                    
                      <vue-editor
                        v-model="candidateModel.introText"
                        @text-change="onContentChange"
                        :editorOptions="editorOptions"
                        placeholder="You can enter a block of text here with line breaks. This is the first paragraph your viewer will see."
                      />                                                             
                    </b-form-group>
                    <b-button
                      v-show="false"
                      ref="submit"
                      type="submit"
                      variant="primary"
                      >Submit</b-button
                    >
                  </b-form>
                </b-card-body>
              </b-card>
              <AdminFooterButton
                @on-save="onSave"
                @on-skip="onSkip"
                @on-back="onBack"
                @on-preview="onPreview"
                :hideSkipButton="false"
                :saving="saving"
                :invalid="invalid"
              />
            </ValidationObserver>
          </b-col>
        </b-row>
      </b-container>
    </main>
    <ModalThemeGuide
      theme-id="introText" 
      :template-id="candidateModel.profileTheme"
    />       
  </div>
</template>

<script>
import DirtyFlagMixin from "@/mixins/dirtyflag.mixin";
import CandidateMixin from "@/mixins/candidate.mixin";
import { VueEditor } from "vue2-editor";
export default {
  name: "AboutIntroText",
  components: {  
    VueEditor
  },
  mixins: [DirtyFlagMixin, CandidateMixin],
  data(){
    return{
      editorOptions: {
        modules: {
          toolbar: false
        }
      }
    }
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    onContentChange() {
      if (
        this.candidateModel.introText.localeCompare(
          this.candidateTemp.introText
        ) !== 0
      )
        this.$refs.observer.flags.dirty = true;
    },
    onPreview() {},
    onBack() {
      this.$router.push("name-position");
    },
    onSkip() {
      this.$router.push("open-content");
    },
    onSave() {
      this.$refs.submit.click();
    },
    onFormSubmit(fromBreadcrumb) {
      let payload = {
        userId: this.$route.params.id,
        text: this.candidateModel.introText
      };

      this.isSubmitting = true;
      this.saving = true;

      this.$refs.observer.validate().then(valid => {
        if (valid) {
          this.candidateService
            .SaveIntroTextAsync(payload)
            .then(res => {
              this.$store.commit("candidates/SET_CANDIDATE", res.data);
              setTimeout(() => {
                this.saving = false;
                if(fromBreadcrumb != true){
                  this.$router.push("open-content");
                }
                
              }, 500);
            })
            .catch(e => {
              this.saving = false;
              this.errorMessage = e;
            });
        } else {
          this.saving = false;
          this.errorMessage = "Please fill up required fields!";
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/admin/_base.scss";
</style>
